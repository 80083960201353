import React from 'react';
import { validateInviteUserAPI } from 'api/UserAPI';
import { HOME } from 'containers/urlConstant';
import { withRouter } from 'react-router-dom';
import { setToastAction } from 'redux/actions';
import { ToastVariant } from 'shared/utils/Constants';
import { connect } from 'react-redux';
import { t } from 'i18next';
import SpinnerLoader from '../loader/SpinnerLoader';
import AuthLayout from 'containers/AuthLayout';

interface WithValidatingProps {
    loading: boolean;
    token: string;
    verify: string;
}

// eslint-disable-next-line  @typescript-eslint/ban-types
export const URLValidationHOC = <P extends object>(
    Component: React.ComponentType<P>,
    heading: string,
    subheading: string,
) => {
    class WithLoading extends React.Component<any, WithValidatingProps> {
        state: WithValidatingProps = {
            loading: false,
            token: '',
            verify: new URLSearchParams(this.props.location.search).get('verify') ?? '',
        };
        componentDidMount() {
            this.checkUrl();
        }

        checkUrl = () => {
            if (this.state.verify) {
                this.setState({ loading: true });
                validateInviteUserAPI({ url: this.state.verify, type: this.props.type })
                    .then((res: any) => {
                        if (res && res.access_token) {
                            this.setState({ loading: false, token: res.access_token });
                        } else {
                            this.props.action(t('something_went_wrong'));
                            throw new Error();
                        }
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                        this.props.history.replace(HOME);
                    });
            } else {
                this.props.history.replace(HOME);
            }
        };
        render() {
            const { ...props } = this.props;
            const { loading } = this.state;
            return (
                <AuthLayout heading={loading ? '' : heading} subheading={loading ? '' : subheading}>
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center h3">
                            <SpinnerLoader /> <div className="text-white mx-2">{t('validating')}</div>
                        </div>
                    ) : (
                        <Component {...(props as P)} {...this.state} />
                    )}
                </AuthLayout>
            );
        }
    }

    const mapStateToProps = (state: any) => state;
    const mapDispatchToProps = (dispatch: any) => {
        return { action: (err: any) => dispatch(setToastAction(ToastVariant.DANGER, err)) };
    };

    return connect(mapStateToProps, mapDispatchToProps)(withRouter(WithLoading));
};
